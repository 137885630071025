import axios from "axios";
import {
  VIEW_REFERRAL_DATA_REQUEST,
  VIEW_REFERRAL_DATA_SUCCESS,
  VIEW_REFERRAL_DATA_FAIL,
  CLEAR_ERRORS,
} from '../constants/fundConstants';

// Helper function to extract error messages
const getErrorMessage = (error) => {
  if (error.response) {
    return error.response.data?.message || 'Something went wrong';
  } else if (error.message) {
    return error.message;
  }
  return 'Something went wrong';
};

// Helper function to get access token
const getAccessToken = () => localStorage.getItem("accessToken");

// View Referral Data
export const viewReferralData = () => async (dispatch) => {
  dispatch({ type: VIEW_REFERRAL_DATA_REQUEST });

  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error('Access denied. Please log in.');
    }

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/viewReferralData`, {
      headers: {
        Authorization: `Bearer ${token}`, // Attach the access token to the request headers
      },
    });

    dispatch({ type: VIEW_REFERRAL_DATA_SUCCESS, payload: data.referrals });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch({
      type: VIEW_REFERRAL_DATA_FAIL,
      payload: errorMessage,
    });
  }
};

// Clear errors action
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
