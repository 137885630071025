import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, clearErrors } from '../actions/userActions';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { error, loading } = useSelector((state) => state.user); // Retrieve error and loading state

  const [isLoggingOut, setIsLoggingOut] = useState(true); // Track logout state

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await dispatch(logout()); // Trigger the logout action
        setIsLoggingOut(false); // Set logging out to false once logout is complete
        navigate('/login'); // Redirect to the login page
      } catch (error) {
        // Handle logout error if there is one
        setIsLoggingOut(false); // End the logout process
      }
    };

    handleLogout();

    // Clear any leftover errors when the component is unmounted or before logout happens
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, navigate]);

  return (
    <div className="logout-page">
      <h2>Logging Out...</h2>
      {loading && <p>Please wait while we log you out...</p>} {/* Show loading message */}
      {!loading && isLoggingOut && <p>Logging you out, please wait...</p>} {/* Logging out message */}
      {error && !loading && !isLoggingOut && <p className="error-message">Logout failed: {error}</p>} {/* Show error message if logout fails */}
    </div>
  );
};

export default LogoutPage;
