import React from "react";
import './home.css';


const Testpage = () => {


  return (
    <>
     <div className="registration-page">
      <div className="registration-form-box">
        <h1>Your ID will be Activated Tomorrow</h1>
       
      </div>
    </div>
    </>
  );
};

export default Testpage;
